import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { getEntry } from "../Services/Contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { RICHTEXT_OPTIONS } from "../Services/Contentful/constants";
import { Link } from "react-router-dom";

export function SkillSingle(props) {
  const [skill, setskill] = useState([]);

  useEffect(() => {
    getEntry(props.match.params.skillId).then((skill) => {
      setskill(skill);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!skill) return <span>Loading...</span>
  const { name, excerpt, details } = skill;
  document.title = name;
  
  return (
    <div className="page-wrap centre max-w-screen-lg ">
      <div className="skill-inner page-inner mid-dark-bg p-5 m-5 rounded-xl mb-5 ">
        <div className="font-medium text-lg">{name}</div>
        <div className="font-medium text-lg">{excerpt}</div>
        {documentToReactComponents(details, RICHTEXT_OPTIONS)}
      </div>
      <div className="back-link page-inner mid-dark-bg p-5 m-5 rounded-xl">
        <Link className="back-link" to="/">
        <p>Back to Home</p>
        </Link>
      </div>
    </div>
  );
}

export default SkillSingle;
