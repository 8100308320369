import React, { useState, useEffect } from "react";
import { getEntries } from "../Services/Contentful";
import { QualificationCard } from "./QualificationCard";

function Qualifications() {
  document.title = "Qualifications";
  const [qualifications, setQualifications] = useState([]);

  useEffect(() => {
    getEntries("qualification","sys.createdAt").then((qualifications) => {
      setQualifications(qualifications);
    });
  }, []);
  if (!qualifications) return <span>Loading...</span>

  return (
    <div className="qualifications-page page-wrap flex">
      <div className="page-inner">
        <h2 className="page-head-h2 font-medium text-lg">Qualifications</h2>
        <div className="">
          {qualifications.map((qualification) => (
            <QualificationCard key={qualification.id} qualification={qualification}></QualificationCard>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Qualifications;
