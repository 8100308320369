export const MenuItems = [
    {
        title: 'Projects',
        path: '/projects',
        cName: 'menu-item'
    },

    {
        title: 'Case Studies',
        path: '/case-studies',
        cName: 'menu-item'
    },
    {
        title: 'Qualifications',
        path: '/qualifications',
        cName: 'menu-item'
    },
    {
        title: 'CV',
        path: '/cv',
        cName: 'menu-item'
    },
    {
        title: 'About',
        path: '/about',
        cName: 'menu-item'
    },
]