import React from "react";
import { RICHTEXT_OPTIONS } from "../Services/Contentful/constants";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export function QualificationCard({ qualification }) {
  const {name, details} = qualification;
  return (
      <div className="qualification centre max-w-screen-lg dark-bg p-5 m-5 rounded-xl mb-5 ">
        <div className="font-medium text-lg">{name}</div>
        <div className="text-base">
        {documentToReactComponents(details, RICHTEXT_OPTIONS)}
        </div>
      </div>
  );
}
