const contentful = require("contentful");
const {REACT_APP_SPACE_ID, REACT_APP_CDA_TOKEN} = process.env;

export async function getEntries(type,order) {
  const client = getClient();
  const result = await client.getEntries({ content_type: type, order: order });
  return result.items.map((item) => mergeIdToFields(item));
}

export async function getEntry(id) {
  const client = getClient();
  const result = await client.getEntry(id);
  return mergeIdToFields(result);
}

function getClient() {
  const client = contentful.createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: REACT_APP_SPACE_ID,
    // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    accessToken: REACT_APP_CDA_TOKEN,
  });
  return client;
}

function mergeIdToFields(item) {
  return { ...item.fields, id: item.sys.id, itemType: item.sys.contentType.sys.id };
}

export function makeSlug(name) {
  return name.toLowerCase()
  .replace(/ /g,'-')
  .replace(/[^\w-]+/g,'');
}

export const Capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
