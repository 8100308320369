import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { makeSlug, Capitalize } from "/"; // reference to index for slug builder
// import { getEntry } from "/"; // reference to index

export const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // console.log('node',node);
      return <div className="rich-p w-7/8 pt-2 pb-2">{children}</div>;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      // console.log(node);
      return (
        <img
          className="rich-img"
          src={node.data.target.fields.file.url}
          alt={node.data.target.sys.id}
        />
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      // console.log("block", node);
      let imgSrc = null;
      let imgAlt = null;
      let roleName = "";
      let id = node.data.target.sys.id;
      let slug = makeSlug(node.data.target.fields.name);
      let contentType = node.data.target.sys.contentType.sys.id;
      let entryLink = "/" + contentType + "/" + slug + "/" + id;
      if (contentType === "qualification") {
        entryLink = "/qualifications";
      }
      if (node.data.target.fields.featureImage) {
        imgSrc = node.data.target.fields.featureImage.fields.file.url;
        imgAlt = node.data.target.fields.featureImage.fields.file.fileName;
      }
      if (node.data.target.fields.roleName) {
        roleName = " : " + node.data.target.fields.roleName;
      }
      return (
        <a href={entryLink}>
          <div className="block-entry mid-dark-bg max-w-screen-lg p-4 rounded-xl">
            <div className="block-entry-left">
              <div className="related-title">
                Related {Capitalize(contentType)}
              </div>
              <div>{node.data.target.fields.name + roleName}</div>
              <div className="block-ex">
                {node.data.target.fields.description} Read More...
              </div>
            </div>
            <div className="block-entry-right">
            <img className="rounded-xl" src={imgSrc} alt={imgAlt} />
            </div>
          </div>
        </a>
      );
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      // console.log("inline", node);
      let id = node.data.target.sys.id;
      let slug = makeSlug(node.data.target.fields.name);
      let contentType = node.data.target.sys.contentType.sys.id;
      let entryLink = "/" + contentType + "/" + slug + "/" + id;
      return (
        <div className="inline-entry">
          <a href={entryLink}>
            <div>{node.data.target.fields.name}</div>
            <div>{node.data.target.fields.description}</div>
          </a>
        </div>
      );
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a className="rich-link" href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    },
  },
};
