import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { getEntry } from "../Services/Contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { RICHTEXT_OPTIONS } from "../Services/Contentful/constants";
import { Link } from "react-router-dom";


export function RoleSingle(props) {
  const [role, setRole] = useState([]);

  useEffect(() => {
    getEntry(props.match.params.roleId).then((role) => {
      setRole(role);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!role) return <span>Loading...</span>
  const { name, link, roleName, writeUp } = role;
  const cleanLink = role.link?.replace(/^(https?:|)\/\//, ''); // not sure why this leaves a trailing /
  document.title = 'Role - ' +name;
  return (
    <div className="role-single page-wrap centre max-w-screen-lg ">
      <div className="role-inner page-inner mid-dark-bg p-5 m-5 rounded-xl mb-5 ">
        <div className="font-medium text-lg">{name}</div>
        <div className="text-base pb-0.5">{roleName}</div>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {cleanLink}
        </a>
        {documentToReactComponents(writeUp, RICHTEXT_OPTIONS)}
      </div>
      <div className="back-link page-inner mid-dark-bg p-5 m-5 rounded-xl">
        <Link className="back-link" to="/cv">
        <p>Back to CV</p>
        </Link>
      </div>
    </div>
  );
}

export default RoleSingle;
