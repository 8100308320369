import React from "react";
import { Link } from "react-router-dom";
import { makeSlug } from "../Services/Contentful";

export function ProjectCard({ project }) {
  const $imageTag = project.featureImage ? (
    <img
      className="object-contain h-60 w-full"
      src={project.featureImage.fields.file.url}
      alt={project.name}
    />
  ) : null;
  return (
    <Link to={"/project/" + makeSlug(project.name) + "/" + project.id}>
      <div className="project centre max-w-screen-lg dark-bg p-5 m-5 rounded-xl mb-5 ">
        <div className="font-medium text-lg">{project.name}</div>
        <div className="text-base">
          <p>{project.description} Read more...</p>
        </div>
        <div className="">{$imageTag}</div>
      </div>
    </Link>
  );
}

// function makeSlug(name) {
//   return name.toLowerCase().replace(" ", "-");
// }
