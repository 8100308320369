import React from "react";
import { Link } from "react-router-dom";
import { makeSlug } from "../Services/Contentful";

export function CaseStudyCard({ caseStudy }) {
  const $imageTag = caseStudy.featureImage ? (
    <img
      className="object-contain h-60 w-full"
      src={caseStudy.featureImage.fields.file.url}
      alt={caseStudy.name}
    />
  ) : null;
  return (
    <Link to={"/case-study/" + makeSlug(caseStudy.name) + "/" + caseStudy.id}>
      <div className="project centre max-w-screen-lg dark-bg p-5 m-5 rounded-xl mb-5 ">
        <div className="font-medium text-lg">{caseStudy.name}</div>
        <div className="text-base">
          <p>{caseStudy.description} Read more...</p>
        </div>
        <div className="">{$imageTag}</div>
      </div>
    </Link>
  );
}
