import React from "react";
// import React, { useState, useEffect } from "react";
// import { getEntry } from "../Services/Contentful";
import { Parallax } from 'react-parallax';


function Test() {
  document.title = "test";

  const image = "src/bwmount.jpg"

  return (
    <div className="App">
      <Parallax bgImage={image}>
          <div>
            inner
          </div>
        </Parallax>
    </div>
  );
}

export default Test;
