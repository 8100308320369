import React from "react";
import { Link } from "react-router-dom";
import { makeSlug } from "../Services/Contentful";
import { DateTime } from "luxon";

export function RoleCard({ role }) {
  let sDate =
    DateTime.fromISO(role.startDate).toFormat("LLLL") +
    " " +
    DateTime.fromISO(role.startDate).toFormat("yyyy");
  let eDate =
    DateTime.fromISO(role.endDate).toFormat("LLLL") +
    " " +
    DateTime.fromISO(role.endDate).toFormat("yyyy");

  return (
    <Link to={"/role/" + makeSlug(role.name) + "/" + role.id}>
      <div className="role centre max-w-screen-lg dark-bg p-5 m-5 rounded-xl mb-5 ">
        <div className="font-medium text-lg pb-0.5">{role.name}</div>
        <div className="text-base pb-0.5">{role.roleName} : {sDate} - {eDate}</div>
        <div className="text-base pb-0.5">
          
        </div>
        <div className="text-base">
          <p>{role.description} Read More...</p>
        </div>
      </div>
    </Link>
  );
}
