import React from "react";
import skytower from "../skytower-blk.jpeg";

function About() {
  document.title = "About Me";

  return (
    <div className="page-wrap flex about-page">
      <div className="page-inner">
        <h2 className="page-head-h2 font-medium text-lg">About Me</h2>
        <div className="dark-bg p-5 mt-40 m-5 rounded-xl page-inner max-w-screen-lg mx-auto">
          <p>Hi I'm Mike :)</p>
          <p>
            I'm a Kiwi with a passion for creating. I'm a qualified engineer,
            marketer, salesman and more. That's enough for now, I've got work to
            do...
          </p>
          <p>
            Take a look around at some of my work history, skills or check out
            my <a href="/projects">personal projects!</a>
          </p>
          <p>
            I usually have a few projects on the go but if you have a something
            that just cant wait, email me at{" "}
            <a href="mailto: mikejscullin@gmail.com ">mikejscullin@gmail.com</a>{" "}
            and we'll see what we can do!
          </p>
          <p>Cheers, Mike Scullin</p>
        </div>
        <div className="about-img p-5 m-5 page-inner max-w-screen-lg mx-auto">
          <img className="mt-10" src={skytower} alt="dark-skytower" />
        </div>
      </div>
    </div>
  );
}

export default About;
