import React from "react";
import { Link } from "react-router-dom";
import { makeSlug } from "../Services/Contentful";
// import { RICHTEXT_OPTIONS } from "../Services/Contentful/constants";
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export function SkillCard({ skill }) {
  const { name, excerpt } = skill;
  return (
    <Link to={"/skill/" + makeSlug(skill.name) + "/" + skill.id}>
      <div className="skill dark-bg align-middle h-full centre max-w-screen-lg p-5 rounded-xl grid grid-cols-8">
        <div className="skill-left col-span-6">
          <div className="font-medium text-base md:text-xl">{name}</div>
          <p className=" text-sm md:text-base light-text">{excerpt}</p>
        </div>
        <div className="skill-right col-span-2 flex justify-center">
          <img className="align-middle max-h-20" src={skill.icon.fields.file.url} alt="" />
        </div>
      </div>
    </Link>
  );
}
