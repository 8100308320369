import React, { useState, useEffect } from "react";
import { getEntries } from "../Services/Contentful";
import { ProjectCard } from "./ProjectCard";

function Projects() {
  document.title = "Projects";
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getEntries("project","fields.order").then((projects) => {
      setProjects(projects);
    });
  }, []);
  

  if (!projects) return <span>Loading...</span>
  
  return (
    <div className="projects-page page-wrap flex">
      <div className="page-inner">
        <h2 className="page-head-h2 font-medium text-lg">Personal Projects</h2>
        <div className="">
          {projects.map((project) => (
            <ProjectCard key={project.id} project={project}></ProjectCard>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Projects;
