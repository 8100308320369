import React from "react";
// import Nav from "./Components/Nav";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import About from "./Components/About";
import Projects from "./Components/Projects";
import CaseStudies from "./Components/CaseStudies";
import Qualification from "./Components/Qualifications";
import ProjectSingle from "./Components/ProjectSingle";
import CaseStudySingle from "./Components/CaseStudySingle";
import SkillSingle from "./Components/SkillSingle";
import RoleSingle from "./Components/RoleSingle";
import Cv from "./Components/Cv";
import Test from "./Components/Test";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./scrollToTop";

import ReactGA from "react-ga";
import Navbar from "./Components/Nav/Navbar";
ReactGA.initialize("UA-63791790-1");

// ScrollToTop();

function App() {
  return (
    <div className="black-bg justify-center text-gray-50">
      <Router>
        <ScrollToTop/>
        <Route
          path="/"
          render={({ location }) => {
            if (typeof window.ga === "function") {
              window.ga("set", "page", location.pathname + location.search);
              window.ga("send", "pageview");
            }
            return null;
          }}
        />
        <div className="content-wrap min-h">
          <Navbar/>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" component={About} />
            <Route path="/projects" exact component={Projects} />
            <Route path="/case-studies" exact component={CaseStudies} />
            <Route path="/qualifications" exact component={Qualification} />
            <Route path="/cv" exact component={Cv} />
            <Route path="/test" exact component={Test} />
            <Route path="/skill/:slug/:skillId" component={SkillSingle} />
            <Route
              path="/project/:slug/:projectId"
              component={ProjectSingle}
            />
            <Route
              path="/case-study/:slug/:caseStudyId"
              component={CaseStudySingle}
            />
            <Route path="/role/:slug/:roleId" component={RoleSingle} />
          </Switch>
          
          <Footer className="footer"/>
        </div>
      </Router>
    </div>
  );
}

export default App;
