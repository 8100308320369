import React, { useState, useEffect } from "react";
import { getEntries } from "../Services/Contentful";
import { CaseStudyCard } from "./CaseStudyCard";

function CaseStudies() {
  document.title = "Case Studies";
  const [caseStudies, setCaseStudies] = useState([]);

  useEffect(() => {
    getEntries("caseStudy","fields.order").then((caseStudies) => {
      setCaseStudies(caseStudies);
    });
  }, []);
  

  if (!caseStudies) return <span>Loading...</span>
  
  return (
    <div className="projects-page page-wrap flex">
      <div className="page-inner">
        <h2 className="page-head-h2 font-medium text-lg">Case Studies</h2>
        <div className="">
          {caseStudies.map((caseStudy) => (
            <CaseStudyCard key={caseStudy.id} caseStudy={caseStudy}></CaseStudyCard>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CaseStudies;
