import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { getEntry } from "../Services/Contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { RICHTEXT_OPTIONS } from "../Services/Contentful/constants";
import { Link } from "react-router-dom";


export function CaseStudySingle(props) {
  const [caseStudy, setCaseStudy] = useState([]);

  useEffect(() => {
    getEntry(props.match.params.caseStudyId).then((caseStudy) => {
      setCaseStudy(caseStudy);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!caseStudy) return <span>Loading...</span>

  const { name, link, writeUp } = caseStudy;
  document.title = name;
  return (
    <div className="page-wrap centre max-w-screen-lg ">
      <div className="page-inner mid-dark-bg p-5 m-5 rounded-xl mb-5 ">
        <div className="font-medium text-lg">{name}</div>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {caseStudy.link}
        </a>
        {documentToReactComponents(writeUp, RICHTEXT_OPTIONS)}
      </div>
      <div className="back-link page-inner mid-dark-bg p-5 m-5 rounded-xl">
        <Link className="back-link" to="/case-studies">
        <p>Back to CaseStudies</p>
        </Link>
      </div>
    </div>
  );
}

export default CaseStudySingle;
