import React from "react";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div className="footer flex">
      <div className="footer-inner footer-links">
        <NavLink to="/about" activeClassName="nav-active">
          About
        </NavLink>
        <NavLink to="/qualifications" activeClassName="nav-active">
          Qualifications
        </NavLink>
        <NavLink to="/projects" activeClassName="nav-active">
          Projects
        </NavLink>
        <NavLink to="/case-studies" activeClassName="nav-active">
          Case Studies
        </NavLink>
        <NavLink to="/cv" activeClassName="nav-active">
          CV
        </NavLink>
      </div>
    </div>
  );
}

export default Footer;
