import React, { useState, useEffect } from "react";
import { getEntries } from "../Services/Contentful";
import { RoleCard } from "./RoleCard";
// import ReactGA from 'react-ga';

function Cvs() {
  document.title = "CV";
  const [roles, setRoles] = useState([]);


  useEffect(() => {
    getEntries("role","-fields.startDate").then((resultRoles) => {
      setRoles(resultRoles);
    });
  }, []);
  if (!roles) return <span>Loading...</span>
  
  return (
    <div className="page-wrap flex">
      <div className="page-inner">
        <h2 className="page-head-h2 font-medium text-lg">Roles</h2>
        <div className="roles">
          {roles.map((role, i) => (
            <RoleCard key={i} role={role}></RoleCard>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Cvs;
